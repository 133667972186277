import clsx from 'clsx';
import Link, { type LinkProps } from 'next/link';
import type { ReactNode } from 'react';
import React from 'react';
import { type Props as TooltipProps, Tooltip } from '../Tooltip';
import styles from './SvgIconButton.module.scss';
import type { InputSize } from '../Input/types';
import type { Placement } from '@floating-ui/react-dom';

export type SvgIconButtonType = 'green' | 'dangerous' | 'white' | 'primary' | 'currentColor';

interface SvgIconProps {
  icon: JSX.Element;
  tooltip?: ReactNode;
  target?: string;
  shape?: 'circle';
  size?: InputSize;
  active?: boolean;
}

interface SvgIconLinkProps extends LinkProps, SvgIconProps {}
export const SvgIconLink: React.FC<SvgIconLinkProps> = ({ icon, tooltip, href, target }) => {
  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        <Link className={styles.button} href={href} target={target}>
          {icon}
        </Link>
      </Tooltip>
    );
  }
  return (
    <Link className={styles.button} href={href} target={target}>
      {icon}
    </Link>
  );
};

interface SvgIconButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'>,
    SvgIconProps {
  type?: SvgIconButtonType;
  shape?: 'circle';
  submitType?: boolean;
  tooltipPlacement?: Placement;
  tooltipAutoPlace?: boolean;
  tooltipProps?: Omit<TooltipProps, 'label' | 'tooltip' | 'children'>;
}
export const SvgIconButton: React.FC<SvgIconButtonProps> = ({
  icon,
  tooltip,
  type,
  onClick,
  className,
  disabled,
  shape,
  size,
  submitType,
  active,
  tooltipProps,
  tooltipPlacement,
  tooltipAutoPlace,
  ...props
}) => {
  if (tooltip) {
    return (
      <Tooltip {...tooltipProps} label={tooltip} placement={tooltipPlacement} autoPlace={tooltipAutoPlace}>
        <button
          className={clsx(
            styles.button,
            type ? styles[type] : undefined,
            shape ? styles[shape] : undefined,
            size ? styles[size] : undefined,
            disabled ? styles.disabled : undefined,
            active ? styles.active : undefined,
            className,
          )}
          onClick={disabled ? undefined : onClick}
          type={submitType ? 'submit' : 'button'}
          // disabled={disabled}
          {...props}
        >
          {icon}
        </button>
      </Tooltip>
    );
  }

  return (
    <button
      className={clsx(
        styles.button,
        type ? styles[type] : undefined,
        shape ? styles[shape] : undefined,
        size ? styles[size] : undefined,
        disabled ? styles.disabled : undefined,
        active ? styles.active : undefined,
        className,
      )}
      onClick={disabled ? undefined : onClick}
      type={submitType ? 'submit' : 'button'}
      // disabled={disabled}
      {...props}
    >
      {icon}
    </button>
  );
};
